import type { NextPage } from 'next';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Head from 'next/head';

import EnterCode from '../../components/StudentFlow/EnterCode/EnterCode';
import { fetchData } from '../../utils/fetch';
import { setUserData as setUserDataAction } from '../../redux/actionAndReducers/user/userActions';
import { RootState } from '../../redux/store';
import { UserRole } from '../../utils/enums';

import { getAbsImagePath } from '../../utils/utilFunctions';
import { vquizPageEnterClickStream } from '../../utils/DataInstrumentation/ClickStreamEvents/ClickStreams';
import { addContext } from '../../services/DatadogService';

type QuizProps = {
  setUserData: (data: any) => void,
  userData: any,
}

const Quiz: NextPage<QuizProps> = ({ setUserData, userData }) => {
  const [isUserApiSucess, setUserApiSuccess] = useState(false);
  useEffect(() => {
    async function fetchLoginData() {
      try {
        const userResp: any = await fetchData({
          method: 'GET',
          url: '/attendee/v1/login-info',
          credentials: 'include',
        });
        setUserData(userResp.response);
      } catch (e) {
        // @ts-ignore
      } finally {
        setUserApiSuccess(true);
      }
      vquizPageEnterClickStream();
    }
    fetchLoginData();
  }, []);

  useEffect(() => {
    if (isUserApiSucess) {
    // adding datadog context
      const params = {
        userId: userData?.userId || null,
      };
      addContext({ logger: params, rum: params });
    }
  }, [isUserApiSucess]);

  return (
    <>
      <Head>
        <title>Welcome to VQuiz</title>
        <meta name="description" content="Vedantu Vquiz" />
        <link rel="icon" href={getAbsImagePath('/cdn/images/favicon.png')} />
      </Head>
      <EnterCode
        isPresenter={userData.role !== UserRole.STUDENT}
      />
    </>
  );
};

const mapDispatchToProps = {
  setUserData: setUserDataAction,
};

const mapStateToProps = (state: RootState) => ({
  userData: state.userData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Quiz);
