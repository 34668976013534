/* eslint-disable @next/next/no-img-element */
import { classNames } from '@vedantuadmin/wave-ui';
import { FunctionComponent } from 'react';

import { getAbsImagePath } from '../../../utils/utilFunctions';

interface PoweredByBannerProps {
  wrapperClass?: string;
}
const PoweredByBanner: FunctionComponent<PoweredByBannerProps> = ({ wrapperClass }) => (
  <div
    className={classNames(
      wrapperClass || 'mb-16 fixed b-0 l-1/2 ml--60',
      'flex-center',
    )}
  >
    <div className="gray-3 txt-12 wt-500 mr-2">
      Powered by
    </div>
    <img
      alt="vedantu"
      src={getAbsImagePath('/cdn/images/leaderboard/vedantu-brand-name.svg')}
      width="50"
      height="13"
    />
  </div>
);

export default PoweredByBanner;