import Router from 'next/router';
import { AskSignUpType, QuizEntityDataType } from '../../../types/quiz';
import { DateUtils, WebView } from '../../enums';
import { getDateTimeIST, isMobileOrTablet, sendClickStreamEvent } from '../../utilFunctions';
import ClickStreamEnums from './ClickStreamEnums';

/* eslint-disable import/no-anonymous-default-export */
export const submitCodeClickStream = (code: string) => {
  const {
    CATEGORY,
    ACTION,
    LABEL,
    KEYS,
  } = ClickStreamEnums.SUBMIT_CODE;
  const data = {
    key1: KEYS.QUIZ_ID,
    value1: code,
  };
  sendClickStreamEvent(CATEGORY, ACTION, LABEL, null, data);
};

export const submitNameClickStream = (quizId: string | number) => {
  const {
    CATEGORY,
    ACTION,
    LABEL,
    KEYS,
  } = ClickStreamEnums.SUBMIT_NAME;
  const data = {
    key1: KEYS.QUIZ_ID,
    value1: quizId,
  };
  sendClickStreamEvent(CATEGORY, ACTION, LABEL, null, data);
};

export const vquizPageEnterClickStream = () => {
  const {
    CATEGORY,
    ACTION,
    LABEL,
  } = ClickStreamEnums.VQUIZ_PAGE_ENTER;
  sendClickStreamEvent(CATEGORY, ACTION, LABEL, null, null);
};

export const enterNamePageClickStream = (quizId: string | number) => {
  const {
    CATEGORY,
    ACTION,
    LABEL,
    KEYS,
  } = ClickStreamEnums.ENTER_NAME_PAGE;
  const data = {
    key1: KEYS.QUIZ_ID,
    value1: quizId,
  };
  sendClickStreamEvent(CATEGORY, ACTION, LABEL, null, data);
};

export const waitingScreenEnterClickStream = (youtubeID: string, quizId: number | string) => {
  const {
    CATEGORY,
    ACTION,
    LABEL,
    KEYS,
  } = ClickStreamEnums.WAITING_SCREEN_ENTER;
  const data = {
    key1: KEYS.QUIZ_ID,
    value1: quizId,
    key2: KEYS.SOURCE_URL,
    value2: `youtube.com/watch?v=${youtubeID}`,
  };
  sendClickStreamEvent(CATEGORY, ACTION, LABEL, null, data);
};

export const toEnterCodeClickStream = () => {
  const {
    CATEGORY,
    ACTION,
    LABEL,
  } = ClickStreamEnums.TO_ENTER_CODE;
  sendClickStreamEvent(CATEGORY, ACTION, LABEL, null, null);
};

export const toEnterNameClickStream = (quizId: string | number) => {
  const {
    CATEGORY,
    ACTION,
    LABEL,
    KEYS,
  } = ClickStreamEnums.TO_ENTER_NAME;
  const data = {
    key1: KEYS.QUIZ_ID,
    value1: quizId,
  };
  sendClickStreamEvent(CATEGORY, ACTION, LABEL, null, data);
};

export const heartClickedClickStream = (quizId: number | string) => {
  const {
    CATEGORY,
    ACTION,
    LABEL,
    KEYS,
  } = ClickStreamEnums.HEART_CLICKED;
  const data = {
    key1: KEYS.QUIZ_ID,
    value1: quizId,
  };
  sendClickStreamEvent(CATEGORY, ACTION, LABEL, null, data);
};

export const inviteLinkClickedClickStream = (quizId: number | string) => {
  const {
    CATEGORY,
    ACTION,
    LABEL,
    KEYS,
  } = ClickStreamEnums.INVITE_LINK_CLICKED;
  const data = {
    key1: KEYS.QUIZ_ID,
    value1: quizId,
  };
  sendClickStreamEvent(CATEGORY, ACTION, LABEL, null, data);
};

export const enterQuizClickedClickStream = (userId: number, userName: string, quizData: QuizEntityDataType, timeDifference: number) => {
  const {
    doubtApp, section, seriesTitle, joinType,
  } = Router.query;
  let platform = isMobileOrTablet() ? WebView.ANDROID : WebView.DESKTOP;
  if (doubtApp === 'true') platform = WebView.MOBILE;
  const {
    CATEGORY,
    ACTION,
    LABEL,
    KEYS,
  } = ClickStreamEnums.ENTER_QUIZ_CLICKED;
  const eventPropertiesObj = {
    userid_last_digit: userId % 10,
    name: userName,
    question_count: quizData.totalQuestions,
    quiz_title: quizData.title,
    quiz_duration: quizData.defaultQuestionTimeInSecs,
    quiz_is_live: false,
    is_loggedin: quizData.askSignup === AskSignUpType.BEFORE ? 'yes' : 'no',
    subject: quizData.subjects,
    quiz_grade: quizData.grade,
    datetime_ist: getDateTimeIST(Date.now() + timeDifference),
    consumption_platform: platform,
    entry_platform: platform,
    quiz_section: section,
    quiz_series_title: seriesTitle,
    quiz_join_type: joinType,
  };
  const data = {
    key1: KEYS.QUIZ_ID,
    value2: quizData.quizId,
    eventProperties: eventPropertiesObj,
  };
  sendClickStreamEvent(CATEGORY, ACTION, LABEL, null, data);
};

export const quizStartedClickStream = (userId: number, userName: string, quizData: QuizEntityDataType, timeDifference: number, isAsync: boolean) => {
  const {
    CATEGORY,
    ACTION,
    LABEL,
    KEYS,
  } = ClickStreamEnums.QUIZ_STARTED;
  const {
    doubtApp, section, seriesTitle, joinType,
  } = Router.query;
  let platform = isMobileOrTablet() ? WebView.ANDROID : WebView.DESKTOP;
  if (doubtApp === 'true') platform = WebView.MOBILE;
  const eventPropertiesObj = {
    userid_last_digit: userId % 10,
    name: userName,
    question_count: quizData.totalQuestions,
    quiz_title: quizData.title,
    quiz_duration: quizData.defaultQuestionTimeInSecs,
    quiz_is_live: !isAsync,
    is_loggedin: quizData.askSignup === AskSignUpType.BEFORE ? 'yes' : 'no',
    subject: quizData.subjects,
    datetime_ist: getDateTimeIST(Date.now() + timeDifference),
    consumption_platform: platform,
    entry_platform: platform,
    quiz_section: section,
    quiz_series_title: seriesTitle,
    quiz_join_type: joinType,
  };
  const data = {
    key1: KEYS.QUIZ_ID,
    value2: quizData.quizId,
    eventProperties: eventPropertiesObj,
  };
  sendClickStreamEvent(CATEGORY, ACTION, LABEL, null, data);
};

export const quizEndedClickStream = (userId: number, userName: string, quizData: QuizEntityDataType, timeDifference: number, isAsync: boolean, endType: string, quizStartTime: number) => {
  const {
    CATEGORY,
    ACTION,
    LABEL,
    KEYS,
  } = ClickStreamEnums.QUIZ_ENDED;
  const {
    doubtApp, section, seriesTitle, joinType,
  } = Router.query;
  let platform = isMobileOrTablet() ? WebView.ANDROID : WebView.DESKTOP;
  if (doubtApp === 'true') platform = WebView.MOBILE;
  const eventPropertiesObj = {
    userid_last_digit: userId % 10,
    name: userName,
    question_count: quizData.totalQuestions,
    quiz_title: quizData.title,
    quiz_duration: quizData.defaultQuestionTimeInSecs,
    quiz_is_live: !isAsync,
    is_loggedin: quizData.askSignup === AskSignUpType.BEFORE ? 'yes' : 'no',
    subject: quizData.subjects,
    datetime_ist: getDateTimeIST(Date.now() + timeDifference),
    consumption_platform: platform,
    entry_platform: platform,
    end_type: endType,
    time_spent: (Date.now() + timeDifference - quizStartTime) / DateUtils.MILLIS_PER_SECOND,
    quiz_section: section,
    quiz_series_title: seriesTitle,
    quiz_join_type: joinType,
  };
  const data = {
    key1: KEYS.QUIZ_ID,
    value2: quizData.quizId,
    eventProperties: eventPropertiesObj,
  };
  sendClickStreamEvent(CATEGORY, ACTION, LABEL, null, data);
};