import { io } from 'socket.io-client';
import { config } from '../config/config';
import { Mode } from '../utils/enums';

type SocketCB = (success?: any) => void;

export type socketUtilsType = {
  socket: any,
  makeSocketConnection: (quizId: string, cb?:SocketCB) => void,
  stopSocket: () => void,
  emitToSocket: (context: string, message: object) => void,
}

export const socketUtils: socketUtilsType = {
  socket: null,
  makeSocketConnection(quizId, cb) {
    const socketConfig = {
      forceNew: true, // whether to reuse an existing connection
      query: {
        quizId: String(quizId),
      },
      // auth: {
      //   'x-ved-token': vCookies.getItem('X-Ved-Token'),
      // },
      transports: ['websocket'],
      timeout: 10000, // connection timeout before an error event is emitted
      reconnectionAttempts: 20, // number of reconnection attempts before giving up
      path: config.mode === Mode.PROD ? '/socket.io' : '/socket.io',
    };
    const hostUrl = `https://${config.quiz.url}/`;
    this.socket = io(hostUrl, socketConfig);
    this.socket.connect();

    this.socket.on('connect', () => {
      cb?.();
      console.log('Socket Connected');
    });

    this.socket.on('disconnect', (reason: string) => {
      console.log('Socket Disconnected', reason);
    });
  },

  stopSocket() {
    this.socket.disconnect();
    console.log('Socket Disconnected');
  },

  emitToSocket(context, message) {
    this.socket.emit(context, message);
  },
};

export const EMIT_CONTEXTS = {
  QUESTION: 'question',
  QUIZ: 'quiz',
  CTQ: 'CTQ',
  STUDENT: 'student',
  DISCONNECTED_FROM_QUIZ: 'DFQ',
};

export const EMIT_TYPES = {
  START_QUESTION: 'stq',
  SHOW_QUESTION: 'shq',
  SHOW_OPTIONS: 'so',
  SUBMIT_ANSWER: 'sa',
  END_QUESTION: 'eq',
  GET_RESULT: 'gr',
  GET_GRAPH: 'gg',
  ERROR: 'err',
  GET_LEADERBOARD: 'glb',
  INDIVIDUAL_LEADERBOARD: 'ilb',
  MISSING_QUESTION_LIBRARY_ID: 'mqli',
  INVALID_QUESTION_LIBRARY_ID: 'iqli',
  UNSUPPORTED_ROLE: 'ur',
  DATA_DISCRIPANCY_REQUEST: 'ddr',
  ANSWER_MISSING: 'am',
  PAST_ENDTIME: 'pe',
  SUBMIT_STATS: 'ss',
  LEADERBOARD: 'lb',
  ONLINE_STUDENT_COUNT: 'o',
  STUDENT_PROFILE_PICTURES: 's',
  ACTION_NOT_ALLOWED: 'ana',
  STUDENT_ROUTE_ERROR: 'sre',
  QUESTION_ALREADY_STARTED: 'qa',
  END_QUIZ: 'e',
  CTQ: 'CTQ',
  MULTIPLE_CONNECTION: 'MC',
};
