import { classNames } from '@vedantuadmin/wave-ui';
import { useRouter } from 'next/router';
import {
  ChangeEvent, FunctionComponent, useRef, useState, KeyboardEvent, useEffect,
} from 'react';

import dynamic from 'next/dynamic';
import {
  QuizProgressStates,
  QuizType,
  ResponseErrorQuizEntityDataType,
  ResponseSuccessQuizEntityDataType,
} from '../../../types/quiz';
import { fetchData } from '../../../utils/fetch';
import PoweredByBanner from '../PoweredByBanner/PoweredByBanner';
import styles from './EnterCode.module.scss';
import { StatusCodes } from '../../../utils/enums';

import { getAbsImagePath } from '../../../utils/utilFunctions';
import Button from '../../common/Button/Button';
import { socketUtils } from '../../../services/SocketService';
import { submitCodeClickStream, toEnterCodeClickStream } from '../../../utils/DataInstrumentation/ClickStreamEvents/ClickStreams';

interface EnterCodeProps {
  isPresenter?: boolean,
}

const QuizEnded = dynamic(() => import('../../QuizContainer/QuizEnded/QuizEnded'));
const QuizInactive = dynamic(() => import('../../QuizContainer/QuizInactive/QuizInactive'));

const EnterCode: FunctionComponent<EnterCodeProps> = ({ isPresenter = false }) => {
  const [code, setCode] = useState('');
  const [quizNotFound, setQuizNotFound] = useState(false);
  const [quizEnded, setQuizEnded] = useState(false);
  const [quizInactive, setQuizInactive] = useState(false);
  const router = useRouter();
  const startTime = useRef(0);

  const [showVedantuBanner, setShowVedantuBanner] = useState(true);

  useEffect(() => {
    if (socketUtils.socket) {
      socketUtils.stopSocket();
    }
  }, []);

  const handleEnterQuiz = () => {
    setQuizEnded(false);
  };

  const handleOnCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(Number(event.target.value))) {
      if (!quizNotFound) setQuizNotFound(true);
    } else if (quizNotFound)setQuizNotFound(false);
    setCode(event.target.value);
  };

  const handleSubmitCode = async () => {
    submitCodeClickStream(code);
    let resp;
    try {
      resp = (await fetchData<ResponseSuccessQuizEntityDataType>({ url: `/assessment/v1/quiz/${code}`, credentials: 'include' })).response;
      startTime.current = resp.startTime;
    } catch (error) {
      const err = error as ResponseErrorQuizEntityDataType;
      if (err.error.code === StatusCodes.BAD_REQUEST || err.error.code === StatusCodes.NOT_FOUND) {
        setQuizNotFound(true);
      }
    }

    if (resp?.type === QuizType.LIVE) {
      if (Date.now() > resp.liveEndTime) {
        setQuizEnded(true);
      } else if (!resp.allowLateJoinees && resp.progressState === QuizProgressStates.LIVE_STARTED) {
        setQuizEnded(true);
      } else if (!isPresenter && resp.startTime > Date.now()) {
        setQuizInactive(true);
      } else {
        router.push(`vquiz/${code}`);
      }
    } else if (resp?.type === QuizType.HYBRID || resp?.type === QuizType.ON_DEMAND) {
      if (Date.now() > resp.onDemandEndTime) {
        setQuizEnded(true);
      } else {
        router.push(`vquiz/${code}`);
      }
    } else if (resp && (resp.startTime > Date.now())) {
      setQuizInactive(true);
    } else if (resp) {
      router.push(`vquiz/${code}`);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13 && code) {
      handleSubmitCode();
    }
  };

  const handleOnFocus = () => {
    toEnterCodeClickStream();
    setShowVedantuBanner(false);
  };

  const handleOnBlur = () => {
    setShowVedantuBanner(true);
  };

  return (
    <>
      {!quizEnded && !quizInactive
    && (
    <div
      className={classNames(
        'enter-code w-1/1 flex-column flex mx-auto h-1/1 align-items-center',
        'px-16 box-border',
        'overflow-auto',
        styles.EnterCode,
        styles.BackGround,
      )}
    >
      <picture>
        <source media="(max-width: 799px)" srcSet={getAbsImagePath('/cdn/images/common/waiting-portait.png')} />
        <source media="(min-width: 800px)" srcSet={getAbsImagePath('/cdn/images/common/waiting.jpeg')} />
        <img className={styles.imagebackground} src={getAbsImagePath('/cdn/images/common/waiting-portait.png')} alt="bg" />
      </picture>

      {/* <img
        className={styles.imagebackground}
        srcSet={`${getAbsImagePath('/cdn/images/common/waiting-portait.jpeg')} 400w, ${getAbsImagePath('/cdn/images/common/waiting.jpeg')} 800w`}
        sizes="(max-width: 600px) 400px,800px"
        alt=""
      /> */}
      <div className={classNames('flex-1 flex-center flex-column w-1/1', styles.enterCodeContainer)}>
        <div className="quiz-brand mb-32">
          <img src={getAbsImagePath('/cdn/images/common/quiz.svg')} width="127" height="53" alt="Quiz" />
        </div>
        <div className="quiz-code-form w-1/1">
          <div className={classNames('mb-16 wt-600 txt-16 txt-center gray-7')}>
            Please enter the code
          </div>
          <input
            onChange={handleOnCodeChange}
            onKeyDown={handleKeyDown}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            value={code}
            placeholder="Enter your code"
            className={classNames(
              styles.inputField,
              'wt-400 txt-16 p-12 primary w-1/1',
              { 'red-3 bor-red-3 mb-8': quizNotFound },
              { 'mb-24': !quizNotFound },
              code !== '' && !quizNotFound ? styles.inputActiveBorder : '',
            )}
          />
          {(quizNotFound
          && (
          <div className="flex flex-row w-1/1 align-items-center red-3 mb-16">
            <div className={classNames('icon-Warning red-1')} />
            <div className="txt-12 wt-400">
              Code is invalid! Look for the code on your screen.
            </div>
          </div>
          )
          )}
        </div>

        <div className="w-1/1">
          <Button
            onClick={handleSubmitCode}
            disabled={!code || quizNotFound}
            addClass={`w-1/1 wt-500 txt-16 disabled:bg-grey-0 disabled:grey-1-opac-6 ${(!code || quizNotFound) ? styles.EnterCodeDisabled : ''}`}
          >
            Submit
          </Button>
        </div>
        {/* <div className="flex-center flex-column w-1/1 mt-24">
          <img src={getAbsImagePath('/cdn/images/common/computer.svg')} alt="computer" width="48" height="48" />
          <div className="wt-400 txt-14 neutral-5 mt-16 mb-24 w-200 txt-center">
            The code is found on screen in front of you
          </div>
        </div> */}
        {showVedantuBanner && <PoweredByBanner />}
      </div>
    </div>
    ) }
      { quizEnded && <QuizEnded quizId={code} handleEnterQuiz={handleEnterQuiz} /> }
      { !isPresenter && quizInactive && <QuizInactive quizId={code} startTime={startTime.current} removeUnloadListener={() => {}} />}
    </>
  );
};

export default EnterCode;