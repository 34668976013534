declare global {
  interface Window {
      DD_LOGS: any;
      DD_RUM: any;
  }
}

type ContextParam = {
    quizId?: number | null,
    userId: number | null,
}

type AddContextParam = {
    logger: ContextParam,
    rum: ContextParam,
}

// function addContextToLogger({ quizId, userId }: ContextParam) {
//   if (quizId) {
//     window.DD_LOGS.addLoggerGlobalContext('quizId', quizId);
//   }
//   if (userId) {
//     window.DD_LOGS.addLoggerGlobalContext('userId', userId);
//   }
// }

// function addContextToRUM({ quizId, userId = 1 }: ContextParam) {
//   window.DD_RUM.setRumGlobalContext({ quizId, userId });
//   window.DD_RUM.setUser({
//     id: String(userId),
//   });
// }

function addContext({ logger, rum }: AddContextParam) {
  try {
    if (window.DD_LOGS && logger) {
      // addContextToLogger(logger);
    }

    if (window.DD_RUM && rum) {
      // addContextToRUM(rum);
    }
  } catch (err) {
    console.error('Error occured while initialsing datadog', err);
  }
}

export { addContext };
