import { classNames } from '@vedantuadmin/wave-ui';
import {
  FunctionComponent,
  ReactNode,
} from 'react';
import styles from './Button.module.scss';

type ButtonProps = {
  onClick?: () => void,
  children: ReactNode,
  variant?: string,
  addClass?: string,
  rippleEffect?: boolean,
  useDefaultStyles?: boolean,
  disabled?: boolean,
}

export const BUTTON_VARIANTS = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
};
export const getBtnVariantClass = (variant: ButtonProps['variant']): string => (variant === BUTTON_VARIANTS.PRIMARY
  ? classNames(styles.PrimaryBtn, 'white-0 bg-orange bor-none')
  : classNames(styles.SecondaryBtn, 'bg-white-0 bor-1 bor-solid bor-gray-6 orange'));

const Button: FunctionComponent<ButtonProps> = ({
  onClick, children, variant = BUTTON_VARIANTS.PRIMARY,
  addClass = '',
  disabled,
  rippleEffect = true,
  useDefaultStyles = true,
}) => {
  const onButtonClick = () => {
    setTimeout(() => {
      onClick?.();
    }, 300);
  };

  return (
    <button
      onClick={onButtonClick}
      disabled={disabled}
      className={classNames(
        'cursor-pointer round-8 txt-16 h-48',
        addClass,
        useDefaultStyles ? getBtnVariantClass(variant) : '',
        rippleEffect ? styles.RippleEffectBtn : '',
      )}
      type="button"
    >
      {children}
      <div className={styles.Ripple} />
    </button>
  );
};

export default Button;
