/* eslint-disable import/no-anonymous-default-export */
const CATG_VQUIZ_YT = 'Vquiz-YT';
const VQUIZ_URL = 'vedantu.com/vquiz';

const KEY = {
  QUIZ_ID: 'Quiz ID',
  SOURCE_URL: 'Source URL',
};

const ACTION = {
  CLICK: 'Click',
  PAGEOPEN: 'pageopen',
  DISPLAY: 'display',
};

const SUBMIT_CODE = {
  CATEGORY: CATG_VQUIZ_YT,
  LABEL: 'Submit Code Clicked',
  ACTION: ACTION.CLICK,
  KEYS: {
    QUIZ_ID: KEY.QUIZ_ID,
  },
};

const SUBMIT_NAME = {
  CATEGORY: CATG_VQUIZ_YT,
  LABEL: 'Submit Name',
  ACTION: ACTION.CLICK,
  KEYS: {
    QUIZ_ID: KEY.QUIZ_ID,
  },
};

const TO_ENTER_CODE = {
  CATEGORY: CATG_VQUIZ_YT,
  LABEL: 'Enter Quiz Code Clicked',
  ACTION: ACTION.CLICK,
};

const TO_ENTER_NAME = {
  CATEGORY: CATG_VQUIZ_YT,
  LABEL: 'Enter Name Clicked',
  ACTION: ACTION.CLICK,
  KEYS: {
    QUIZ_ID: KEY.QUIZ_ID,
  },
};

const HEART_CLICKED = {
  CATEGORY: CATG_VQUIZ_YT,
  LABEL: 'Heart Clicked',
  ACTION: ACTION.CLICK,
  KEYS: {
    QUIZ_ID: KEY.QUIZ_ID,
  },
};

const INVITE_LINK_CLICKED = {
  CATEGORY: CATG_VQUIZ_YT,
  LABEL: 'Copy Invite Clicked',
  ACTION: ACTION.CLICK,
  KEYS: {
    QUIZ_ID: KEY.QUIZ_ID,
  },
};

const VQUIZ_PAGE_ENTER = {
  CATEGORY: CATG_VQUIZ_YT,
  LABEL: VQUIZ_URL,
  ACTION: ACTION.PAGEOPEN,
};

const ENTER_NAME_PAGE = {
  CATEGORY: CATG_VQUIZ_YT,
  LABEL: VQUIZ_URL,
  ACTION: ACTION.PAGEOPEN,
  KEYS: {
    QUIZ_ID: KEY.QUIZ_ID,
  },
};

const WAITING_SCREEN_ENTER = {
  CATEGORY: CATG_VQUIZ_YT,
  LABEL: VQUIZ_URL,
  ACTION: ACTION.PAGEOPEN,
  KEYS: {
    QUIZ_ID: KEY.QUIZ_ID,
    SOURCE_URL: KEY.SOURCE_URL,
  },
};

const ENTER_QUIZ_CLICKED = {
  CATEGORY: CATG_VQUIZ_YT,
  LABEL: 'Vquiz_EnterQuizClicked',
  ACTION: ACTION.CLICK,
  KEYS: {
    QUIZ_ID: KEY.QUIZ_ID,
  },
};

const QUIZ_STARTED = {
  CATEGORY: CATG_VQUIZ_YT,
  LABEL: 'Vquiz_QuizStarted',
  ACTION: ACTION.DISPLAY,
  KEYS: {
    QUIZ_ID: KEY.QUIZ_ID,
  },
};

const QUIZ_ENDED = {
  CATEGORY: CATG_VQUIZ_YT,
  LABEL: 'Vquiz_Ended',
  ACTION: ACTION.CLICK,
  KEYS: {
    QUIZ_ID: KEY.QUIZ_ID,
  },
};

export default {
  SUBMIT_CODE,
  SUBMIT_NAME,
  VQUIZ_PAGE_ENTER,
  WAITING_SCREEN_ENTER,
  TO_ENTER_CODE,
  HEART_CLICKED,
  INVITE_LINK_CLICKED,
  ENTER_NAME_PAGE,
  TO_ENTER_NAME,
  ENTER_QUIZ_CLICKED,
  QUIZ_STARTED,
  QUIZ_ENDED,
};
